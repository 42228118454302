import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from "swiper";

let PROGRESS_INTERVAL;

export function swipersInit() {
  homeHeroSwiper();
  homeHighlightsSwiper();
}

function homeHeroSwiper() {
  const selector = ".home-hero-swiper";
  const container = document.querySelector(selector);
  if (!container) return;

  const slidesCount = Number(container.getAttribute("data-slides-count"));

  new Swiper(selector, {
    modules: [Navigation, Pagination, Autoplay],
    speed: 1000,
    loop: slidesCount > 1 ? true : false,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
	  clickable: true,
    },
  });
}

function setHomeHighlightSwiperCircleProgress(percent) {
  var circles = document.querySelectorAll(
    ".home-highlights-container .progress-ring__circle"
  );

  if (!circles) return;

  circles.forEach((circle) => {
    var radius = circle.r.baseVal.value;
    var circumference = radius * 2 * Math.PI;

    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = `${circumference}`;

    // set progress
    const offset = circumference - (percent / 100) * circumference;
    circle.style.strokeDashoffset = offset;
  });
}

function progressTimer(duration) {
  let intervalTime = duration / 100;
  let percent = 0;

  clearInterval(PROGRESS_INTERVAL);
  PROGRESS_INTERVAL = setInterval(frame, intervalTime);

  function frame() {
    if (percent >= 100) {
      clearInterval(PROGRESS_INTERVAL);
    } else {
      percent++;
      setHomeHighlightSwiperCircleProgress(percent);
      // console.log("% = ", percent);
    }
  }
}

function homeHighlightsSwiper() {
  const selector = ".home-highlights-swiper";
  const container = document.querySelector(selector);
  if (!container) return;

  const slidesCount = Number(container.getAttribute("data-slides-count"));

  const loop = slidesCount > 1 ? true : false;
  const slideTime = 10000;
  // home highlights
  new Swiper(selector, {
    modules: [EffectFade, Autoplay],
    effect: "fade",
    speed: 1000,
    loop: loop,
    autoplay: {
      delay: slideTime,
      disableOnInteraction: false,
    },
    on: {
      init: function () {
        progressTimer(slideTime);
      },
      slideChange: function () {
        progressTimer(slideTime);
      },
    },
  });
}
