import axios from "axios";
import gsap from "gsap";

import { isPageTemplate } from "./utils";

function addSearchTab() {
  const tabs = document.getElementById("shows-page-tabs");
  if (!tabs) return;

  const existingNewTab = tabs.querySelector(".tab-search-results");
  if (existingNewTab) return;

  //  clear active
  const activeTabs = tabs.querySelectorAll(".is-active");
  if (activeTabs) {
    activeTabs.forEach((tab) => {
      tab.classList.remove("is-active");
    });
  }

  const newTab = document.createElement("a");
  newTab.className = "tab-search-results is-active";
  newTab.href = "#";
  newTab.innerText = "Search";
  tabs.append(newTab);
}

async function fetchShows(showType) {
  const resultsContainer = document.getElementById("shows-grid");
  try {
    const res = await axios(`/wp-json/shows/get?type=${showType}`);
    if (res.status !== 200) throw "Problem";

    if (!res.data?.length) return;

    res.data.forEach((result) => {
      const el = renderSearchResult(result);
      resultsContainer.append(el);
    });
  } catch (e) {
    console.log(e);
    alert("Sorry, the was a problem communicating with the server");
  }
}

function searchResultsReset() {
  const resultsContainer = document.getElementById("shows-grid");
  resultsContainer.innerHTML = "";

  const tabs = document.getElementById("shows-page-tabs");
  if (!tabs) return;

  const existingNewTab = tabs.querySelector(".tab-search-results");

  if (existingNewTab) existingNewTab.remove();

  const showType = tabs.getAttribute("data-show-type-active");
  console.log(showType);
  const showTypeTab = tabs.querySelector(`a[data-show-type='${showType}']`);
  if (showTypeTab) {
    showTypeTab.classList.add("is-active");
    fetchShows(showType);
  }
}

function searchResultsClear() {
  const resultsContainer = document.getElementById("shows-grid");
  if (!resultsContainer) return;
  resultsContainer.innerHTML = "";
}

function searchSuggestion(suggestion) {
  const searchSuggestion = document.getElementById("shows-suggestion");
  const searchInput = document.getElementById("shows-page-search-input");
  if (!searchSuggestion) return;

  searchSuggestion.innerText = "";

  if (suggestion == "") return;

  const preTextEl = document.createElement("span");
  preTextEl.innerText = "Did you mean ";

  const postTextEl = document.createElement("span");
  postTextEl.innerText = " ?";

  const link = document.createElement("a");
  link.href = "#";
  link.innerText = suggestion.trim();
  link.addEventListener("click", (e) => {
    e.preventDefault();
    if (searchInput) searchInput.value = suggestion.trim();
    handleSearchInputChange({ target: searchInput });
  });

  searchSuggestion.append(preTextEl, link, postTextEl);
}

async function doSearch(query) {
  console.log("doSearch");
  const searchURIBase = "/wp-json/shows/search";

  try {
    const res = await axios(`${searchURIBase}?s=${encodeURIComponent(query)}`);
    if (res.status !== 200) throw "Server Problem";

    let returnObj = {};

    // no results
    if (!res.data || !res.data?.results.length) returnObj.results = false;

    // has results
    if (res.data?.results.length) returnObj.results = res.data.results;

    // suggestion
    returnObj.suggestion = !res.data?.suggestion ? false : res.data?.suggestion;

    // check if suggestion is just capitlisation
    if (returnObj.suggestion) {
      let _suggestion = returnObj.suggestion.toLowerCase().trim();
      if (query.toLowerCase().trim() === _suggestion) {
        returnObj.suggestion = false;
      }
    }

    // add "search" tab
    addSearchTab();

    return returnObj;
  } catch (e) {
    console.error(e);
    alert("Sorry, the was a problem communicating with the server");
    throw "error getting search results";
  }
}

function searchResultsRender(results) {
  const resultsContainer = document.getElementById("shows-grid");
  if (!results || !resultsContainer) return;

  results.forEach((result) => {
    const el = renderSearchResult(result);
    resultsContainer.append(el);
  });
}

function clearResultsCount() {
  const resultsCountEl = document.getElementById("shows-result-count");
  if (!resultsCountEl) return;
  resultsCountEl.innerHTML = "";
}

async function handleSearchInputChange({ target: input }) {
  // check for small queries and reset
  if (input.value.length < 3) {
    let reset = input.value.length === 0 ? true : false;
    if (reset) {
      searchResultsReset();
      clearResultsCount();
    }
    return;
  }

  try {
    const { results, suggestion } = await doSearch(input.value);

    if (!results) {
      // no results
      searchResultsClear();
      updateResultsCount(0);
    } else {
      // has results
      updateResultsCount(results.length);
      searchResultsClear(); // clear previous results
      searchResultsRender(results);
    }
    // suggstion
    if (suggestion) {
      searchSuggestion(suggestion);
    } else {
      searchSuggestion("");
    }
  } catch (e) {
    console.error(e);
    searchResultsReset();
  }
}

function showsPageSearch() {
  const searchInput = document.getElementById("shows-page-search-input");
  if (!searchInput) return;

  searchInput.addEventListener("keyup", handleSearchInputChange);
}

function updateResultsCount(count) {
  const resultsCountEl = document.getElementById("shows-result-count");
  if (!resultsCountEl) return;

  let name = count == 1 ? "show" : "shows";
  let _count = count === 0 ? "No" : count;
  resultsCountEl.innerText = `${_count} ${name} found`;
}

function renderSearchResult(result) {
  const el = document.createElement("div");
  el.className = "show-card-wrap";
  el.innerHTML = `    
    <div class="swiper-slide show-card">
    <a href="${result.url}" class="card-image">
      <img src="${result.image_portrait_url}" alt="${result.title}">
    </a>
    <div class="card-info">
      <h3 class="card-title"><span>${result.title}</span></h3>
      <div class="card-footer">
        <a href="${result.url}">Learn More</a>
        <a href="${result.url}#bookings"><span>Book Now</span></a>
      </div>
    </div>
  </div>

  `;
  return el;
}

export function showsPageInit() {
  if (!isPageTemplate("page-shows")) return;

  showsPageSearch();

  // page title
  const pageTitle = document.querySelector(".page-title");
  if (pageTitle) pageTitle.classList.add("show");

  // shows head
  const showsHead = document.querySelector(".shows-head");
  if (showsHead) showsHead.classList.add("show");

  // shows search
  const showsSearch = document.querySelector(".shows-search");
  if (showsSearch) showsSearch.classList.add("show");
  // shows content
  const showsContent = document.querySelector(".shows-page-content");
  if (showsContent) showsContent.classList.add("show");

  // show cards
  gsap.from(".show-card-wrap", {
    opacity: 0,
    y: 20,
    delay: 0.4,
    duration: 1.5,
    stagger: 0.15,
  });
}
